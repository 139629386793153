import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Freight Services</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                  Freight Services
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="locations-1" id="locations">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subhny">Our Freight Services</h6>
              <h3 className="title-w3l mb-3">Freight Services</h3>
            </div>
            {/*/row-1*/}
            <div className="row pt-4">
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Air">
                      <img
                        className="img-fluid"
                        src="assets/images/Air Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Air">
                        <h3 className="title">Air Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Sea">
                      <img
                        className="img-fluid"
                        src="assets/images/Sea Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Sea">
                        <h3 className="title">Sea Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Surface">
                      <img
                        className="img-fluid"
                        src="assets/images/Surface Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Surface">
                        <h3 className="title">Surface Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Door">
                      <img
                        className="img-fluid"
                        src="assets/images/Door To Door Logistics.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Door">
                        <h3 className="title">Door To Door Logistics</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Courier">
                      <img
                        className="img-fluid"
                        src="assets/images/Courier Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Courier">
                        <h3 className="title">Courier Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Warehousing">
                      <img
                        className="img-fluid"
                        src="assets/images/Warehousing & Packaging.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Warehousing">
                        <h3 className="title">Warehousing & Packaging</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
