import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">Trading Services</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> Trading Services
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-ab-page " id="about1">
        <div className="container py-lg-5 py-md-4 py-2 ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className=" text-left">
            <h3 className="title-w3l mb-2 text-center">Automotive</h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div className="col-lg-7 abw3-img mb-lg-0 mb-5">
              <img
                src="assets/images/Automotive.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="col-lg-5 ps-lg-5">
              <p>
                Whether you're involved in shipping essential production or
                service components, such as automobiles, trucks, or the latest
                electric vehicles, having a reliable and experienced shipping
                partner is of paramount importance. In the complex world of
                freight forwarding, such a partner serves as a critical link in
                your supply chain, ensuring uninterrupted operations. As the
                automotive industry steadily evolves toward a more sustainable
                and eco-friendly future, the need for an agile and transparent
                supply chain solution becomes evident. {companyname}, your
                trusted partner, is ready to deliver just that – a swift,
                adaptable, and transparent logistics solution tailored to the
                evolving needs of the automotive sector.
              </p>
            </div>
          </div>
        </div>
        <div className="container ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className=" text-left">
            <h3 className="title-w3l mb-2 text-center">Fruits & Vegetables</h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div className="col-lg-5 ps-lg-5">
              <p>
                {companyname} specializes in offering meticulous, precise,
                and robust procedures designed to securely transport hazardous
                and potentially dangerous goods, such as chemicals and
                petrochemicals. With our extensive experience as experts in the
                transportation of hazardous cargoes, you can trust {companyname} to oversee the safe shipment of your cargo while ensuring
                full compliance with all relevant regulations. Additionally, our
                expertise extends to managing unforeseen contingencies,
                providing you with comprehensive support no matter where your
                shipments are destined across the world.
              </p>
            </div>
            <div className="col-lg-7 abw3-img mb-lg-0 mb-5">
              <img
                src="assets/images/Fruits & Vegetables.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page " id="about1">
        <div className="container py-lg-5 py-md-4 py-2 ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className=" text-left">
            <h3 className="title-w3l mb-2 text-center">Mining & Minerals</h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div className="col-lg-7 abw3-img mb-lg-0 mb-5">
              <img
                src="assets/images/Mining & Minerals.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="col-lg-5 ps-lg-5">
              <p>
                With decades of experience in catering to the food and beverage
                industries, {companyname} possesses an in-depth
                understanding of the sector's unique requirements. We recognize
                the critical importance of providing comprehensive global port
                coverage, ensuring the availability of suitable equipment, and
                maintaining dependable scheduling practices. Our commitment to
                meeting these specific needs sets us apart as a trusted partner
                in the complex world of food and beverage logistics, ensuring
                the safe and timely delivery of these precious commodities to
                consumers worldwide.
              </p>
            </div>
          </div>
        </div>
        <div className="container ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className=" text-left">
            <h3 className="title-w3l mb-2 text-center">Pharmaceuticals</h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div className="col-lg-5 ps-lg-5">
              <p>
                In today's consumer-driven market, the demand for a consistent
                supply of fresh, delicious, and visually appealing fruits has
                never been higher. However, successfully transporting these
                delicate and perishable goods from the farm to their final
                destination in impeccable condition, while preserving their
                taste, freshness, texture, and aroma, presents a formidable
                challenge. It necessitates a combination of speed, adaptability,
                and state-of-the-art temperature control technology to ensure
                that these fruits reach consumers in the best possible
                condition.
              </p>
            </div>
            <div className="col-lg-7 abw3-img mb-lg-0 mb-5">
              <img
                src="assets/images/Pharmaceuticals.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page " id="about1">
        <div className="container py-lg-5 py-md-4 py-2 ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className=" text-left">
            <h3 className="title-w3l mb-2 text-center">
              Chemicals & Petrochemicals
            </h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div className="col-lg-7 abw3-img mb-lg-0 mb-5">
              <img
                src="assets/images/Chemicals & Petrochemicals.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="col-lg-5 ps-lg-5">
              <p>
                For numerous decades, {companyname} has been a trusted
                facilitator in connecting the mining and minerals extraction
                sectors with customer markets spanning the globe. Given the
                pivotal role that mining and mineral products play across
                various industries, including construction, technology,
                manufacturing, and energy production, {companyname}'s
                proficiency in delivering swift and dependable transit times
                plays a crucial role in ensuring the seamless operation of your
                supply chain.
              </p>
            </div>
          </div>
        </div>
        <div className="container ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className=" text-left">
            <h3 className="title-w3l mb-2 text-center">Food & Beverages</h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div className="col-lg-5 ps-lg-5">
              <p>
                The logistics involved in pharmaceutical transportation
                necessitate strict adherence to regulatory codes to ensure the
                punctual and damage-free delivery of all products, including
                medications and vaccines. At {companyname}, we take pride in
                our commitment to upholding Good Distribution Practice (GDP)
                compliant procedures. We offer dependable and cost-effective
                deliveries to destinations worldwide, ensuring that your
                pharmaceutical shipments are handled with the utmost care and
                compliance.
              </p>
            </div>
            <div className="col-lg-7 abw3-img mb-lg-0 mb-5">
              <img
                src="assets/images/Food & Beverages.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
