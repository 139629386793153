import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <section
        className="w3l-main-slider banner-slider position-relative w3-banner jarallax"
        style={{ paddingTop: "100px" }}
        id="home"
      >
        <video autoPlay loop muted>
          <source src="assets/images/video.mp4"></source>
        </video>
      </section>

      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2 ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="title-content text-left">
            <h6 className="title-subhny">About Us</h6>
            <h3 className="title-w3l mb-2">
              Shipping Challenges, Delivering Solutions.
            </h3>
          </div>
          <div className="row mt-5 pb-5 ab-inf-stat position-relative">
            <div
              className="col-lg-7 abw3-img mb-lg-0 mb-5 mt-4 "
              style={{ paddingTop: "40px" }}
            >
              <img
                src="assets/images/About 1.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="col-lg-5 ps-lg-5">
              <p>
                At {companyname}, our commitment to the shipping sector goes
                beyond the ordinary. With our headquarters strategically
                situated in Mumbai, Maharashtra, one of the world's leading
                maritime hubs, we have leveraged this advantageous location to
                establish ourselves as a prominent player in the international
                freight forwarding arena. Our journey has been marked by
                unwavering dedication to excellence...
              </p>
              <div className="w3banner-content-btns d-flex">
                <Link
                  to="/About"
                  className="btn btn-style btn-primary mt-lg-5 mt-2"
                >
                  Read More{" "}
                </Link>
              </div>
              <div className="w3l-stats-inner-inf">
                <div className="row stats-con">
                  <div className="col-12  stats_info counter_grid">
                    <div className="stats_infoinhny">
                      <h2>Welcome to {companyname}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-1" id="locations">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subhny">Our Freight Services</h6>
              <h3 className="title-w3l mb-3">Freight Services</h3>
            </div>
            {/*/row-1*/}
            <div className="row pt-4">
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Air">
                      <img
                        className="img-fluid"
                        src="assets/images/Air Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Air">
                        <h3 className="title">Air Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Sea">
                      <img
                        className="img-fluid"
                        src="assets/images/Sea Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Sea">
                        <h3 className="title">Sea Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Surface">
                      <img
                        className="img-fluid"
                        src="assets/images/Surface Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Surface">
                        <h3 className="title">Surface Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Door">
                      <img
                        className="img-fluid"
                        src="assets/images/Door To Door Logistics.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Door">
                        <h3 className="title">Door To Door Logistics</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Courier">
                      <img
                        className="img-fluid"
                        src="assets/images/Courier Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Courier">
                        <h3 className="title">Courier Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Warehousing">
                      <img
                        className="img-fluid"
                        src="assets/images/Warehousing & Packaging.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Warehousing">
                        <h3 className="title">Warehousing & Packaging</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-features py-5" id="features">
        <div className="container py-lg-5">
          <div className="row pb-lg-5 mb-lg-5 pt-lg-3">
            <div className="col-lg-5 text-left">
              <h6 className="title-subhny two">Why Choose Us</h6>
              <h3 className="title-w3l two">Solutions That Move You Forward</h3>
            </div>
            <div className="col-lg-7 text-left ps-lg-5">
              <p className="w3p-white">
                With a deep-rooted commitment to integrity, safety, and
                sustainability, we navigate the complex waters of the shipping
                industry with precision, ensuring that our clients' cargo
                reaches its destination securely and on time.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-features-grids">
        <div className="container">
          <div className="main-cont-wthree-2">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-bullseye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Mission</Link>
                  </h4>
                  <p className="text-para">
                    At our core, we are committed to providing our clients with
                    a comprehensive spectrum of cost-efficient forwarding
                    solutions. Our mission revolves around offering innovative,
                    reliable, and tailored logistics services that enable
                    businesses to thrive in an ever-evolving global marketplace.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-eye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Vision</Link>
                  </h4>
                  <p className="text-para">
                    We are driven by a steadfast dedication to delivering
                    top-tier customer service in the realm of freight
                    forwarding. Our vision encompasses a future where businesses
                    can entrust us with their logistics needs, knowing that we
                    will provide solutions that exceed their expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-team-main team py-5" id="team">
        <div className="container py-lg-5">
          <div className="text-center mb-2">
            <h6 className="title-subhny">Trading Services</h6>
            <h3 className="title-w3l">Trading Solution We Give</h3>
          </div>
          <div className="row team-row justify-content-center">
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Automotive.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Automotive</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            {/* end team member */}
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Fruits & Vegetables.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Fruits & Vegetables</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Mining & Minerals.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Mining & Minerals</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Pharmaceuticals.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Pharmaceuticals</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Chemicals & Petrochemicals.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Chemicals & Petrochemicals</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Food & Beverages.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Food & Beverages</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
