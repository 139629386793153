import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <footer className="w3l-footer">
        <div className="w3l-footer-16 py-5">
          <div className="container py-md-5">
            <div className="row footer-p">
              <div className="col-lg-8 col-md-6 pe-lg-5">
                <h2 className="footerw3l-logo" style={{ color: "white " }}>
                  <Link className="navbar-brand" to="/">
                    <img
                      src="assets/images/logo.png"
                      alt="logo"
                      style={{ height: "100px" }}
                    ></img>
                  </Link>

                  {companyname}
                </h2>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-0 mt-4 pl-xl-0">
                <h3>Newsletter</h3>
                <div className="end-column">
                  <form
                    className="subscribe d-flex"
                    action="/php/thankyou-subscribe.php "
                    method="POST"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                      <input
                        onChange={(event) => handleSubemailChange(event)}
                        id="subemail"
                        value={subemail}
                        type="email"
                        name="subemail"
                        placeholder="Enter your email address"
                        required
                      />
                      <button
                        type="submit"
                        className="btn btn-secondary"
                      >
                       <i className="fas fa-paper-plane" />
                      </button>
                  </form>
                  <h5 style={{ color: "red" }}>{result}</h5>
                  
                </div>
              </div>
            </div>
            <div className="row footer-p mt-5 pt-lg-4">
              <div className="col-lg-4 col-md-6 pe-lg-5">
                <div className="column">
                  <h3>Phone</h3>
                  <p>{companynumber}</p>
                </div>
                <div className="column mt-lg-5 mt-4">
                  <h3>Address </h3>
                  <p>{companyaddress}</p>
                </div>
                <div className="column  mt-lg-5 mt-4">
                  <h3>Support</h3>
                  <p>
                    <Link to="mailto: {companyemail}" className="mail">
                      {companyemail}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="column">
                  <h3> Useful Links</h3>
                </div>
                <div className="column pt-2">
                  <Link to="/Home">
                    <p>Home</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Home">
                    <p>About Us</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Home">
                    <p>Why Choose Us</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Services">
                    <p> Freight Services</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Trading">
                    <p> Trading Services</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Home">
                    <p>Contact Us</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Home">
                    <p>Get Quote</p>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="column">
                  <h3>Services Links</h3>
                </div>
                <div className="column pt-2">
                  <Link to="/Air">
                    <p>Air Freight</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Sea">
                    <p>Sea Freight</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Surface">
                    <p>Surface Freight</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Door">
                    <p>Door To Door Logistics</p>
                  </Link>
                </div>
                <div className="column pt-2">
                  <Link to="/Courier">
                    <p>Courier Freight</p>
                  </Link>
                </div>

                <div className="column pt-2">
                  <Link to="/Warehousing">
                    <p>Warehousing & Packaging</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="below-section pt-lg-4 mt-5">
              <div className="row">
                <p className="copy-text col-lg-7">
                  © 2023 {companyname}. All rights reserved.
                </p>
                <div className="col-lg-5 w3footer-gd-links d-flex">
                  <Link to="/Privacy">Privacy Policy</Link>
                  <Link to="/Terms" className="mx-3">
                    Terms of service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
