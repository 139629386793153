import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">Air Freight</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> Air Freight
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5 pt-0">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row bloghny-page justify-content-center">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <Link to="blog-single.html" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Air Freight.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <h4>
                      <Link className="blog-desc">Air Freight</Link>
                    </h4>
                    <p>
                      <p>
                        Our capabilities extend globally, allowing us to
                        efficiently manage your shipments across various
                        transportation modes. We offer expert advice to ensure
                        your valuable orders reach your clients both promptly
                        and economically.
                      </p>

                      <p>
                        We maintain vigilant oversight of carrier statuses to
                        guarantee that your shipments consistently arrive at
                        their intended destinations on time. Irrespective of
                        cargo size, type, or value, we are dedicated to helping
                        you identify the most efficient, secure, and
                        economically advantageous shipping solutions.
                      </p>

                      <p>
                        Our substantial shipping volumes empower us to provide
                        discounted spot tariffs to large and frequent shippers,
                        further enhancing the cost-effectiveness of our services
                        for your benefit.
                      </p>

                      <p>
                        <strong>Details of Our Services:</strong>
                      </p>

                      <p>1. Consolidation</p>
                      <p>2. Direct shipments</p>
                      <p>3. DAP/DDP services</p>
                      <p>4. Handling of Dangerous & ODC cargo</p>
                      <p>5. Online Cargo tracking</p>
                      <p>6. Project cargo management</p>
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
              {/*/w3l-blopagew3-right*/}
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5 order-lg-first order-last">
                <aside className="sidebar pe-lg-4">
                  <div className="sidebar-blog-category">
                    <div className="sidebar-widget sidebar-blog-category">
                      <div className="sidebar-title">
                        <h4>Our Freight Services</h4>
                      </div>
                      <ul className="blog-cat">
                        <li>
                          <Link to="/Air">
                            <i className="fa fa-angle-right me-2" />
                            Air Freight
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/Sea">
                            <i className="fa fa-angle-right me-2" />
                            Sea Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Surface">
                            <i className="fa fa-angle-right me-2" />
                            Surface Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Door">
                            <i className="fa fa-angle-right me-2" />
                            Door To Door Logistics
                          </Link>
                        </li>
                        <li>
                          <Link to="/Courier">
                            <i className="fa fa-angle-right me-2" />
                            Courier Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Warehousing">
                            <i className="fa fa-angle-right me-2" />
                            Warehousing & Packaging
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="sidebar-widget sidebar-blog-category">
                      <div className="side-post-banner mb-5">
                        <h3 className="title-left">Have Any Query</h3>
                        <Link
                          to="/Contact"
                          className="btn-style btn btn-primary mt-4"
                        >
                          {" "}
                          Contact Us{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              {/*//w3l-blopagew3-right*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
