import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">Contact Us</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> Contact
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/*//breadcrumb*/}
      {/*/contact-form*/}
      <section className="w3l-contact-main py-5" id="contact">
        <div className="container py-lg-5 py-md-3">
          <div className="w3header-section text-center mb-md-5 mb-4">
            <h6 className="title-subhny">Contact Info</h6>
            <h3 className="title-w3l mb-5">
            Your Success is Our Priority
            </h3>
            <p>Join hands with us by filling out the form, and let's embark on a journey of success together.</p>
          </div>
          <div className="w3l-contact-info">
            <div className="row contact-infos">
              <div className="col-lg-4 col-md-6">
                <div className="single-contact-infos">
                  <div className="icon-box">
                    {" "}
                    <span className="fas fa-map-marked-alt" />
                  </div>
                  <div className="text-box">
                    <h3 className="mb-2">Address</h3>
                    <p>{companyaddress}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                <div className="single-contact-infos">
                  <div className="icon-box">
                    {" "}
                    <span className="fas fa-phone-alt" />
                  </div>
                  <div className="text-box">
                    <h3 className="mb-2">Phone</h3>
                    <p>
                    {companynumber}
                    </p>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                <div className="single-contact-infos">
                  <div className="icon-box">
                    {" "}
                    <span className="fas fa-envelope-open-text" />
                  </div>
                  <div className="text-box">
                    <h3 className="mb-2">Email</h3>
                    <p>
                      {" "}
                      <a href="mailto:{companyemail}">{companyemail}</a>
                    </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/contact-map*/}
      <section className="w3l-contact-main" id="contact">
        <div className="map pt-lg-3">
          <iframe
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9602783350133!2d72.99922817520509!3d19.06548398213686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c116c44b2e41%3A0xf25d6f87755145ba!2sHaware%20Fantasia%20Business%20Park!5e0!3m2!1sen!2sin!4v1695627975885!5m2!1sen!2sin"
  allowFullScreen
          />
        </div>
      </section>
      {/*//contact-map*/}
      {/*/contact-form*/}
      <section className="w3l-contact-main" id="contact">
        <div className="contact-infhny py-5">
          <div className="container py-lg-5">
            <div className="top-map">
              <div className="map-content-9">
              <form
                  id="contactForm"
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          required
                        />
                        <label htmlFor="name">Enter your first name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                          required
                        />
                        <label htmlFor="email">Enter your last name</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                          required
                        />
                        <label htmlFor="subject">Enter your Email</label>
                      </div>
                    </div>{" "}
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                          required
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary py-3 px-5"
                        type="submit"
                        defaultValue=""
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
