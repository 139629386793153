import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">Door To Door Logistics</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> Door To Door
                  Logistics
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5 pt-0">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row bloghny-page justify-content-center">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <Link to="blog-single.html" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Door To Door Logistics.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <h4>
                      <Link className="blog-desc">Door To Door Logistics</Link>
                    </h4>
                    <p>
                      <p>
                        At {companyname}, we take immense pride in our
                        meticulous cargo monitoring process, ensuring that your
                        shipment receives our vigilant care from the moment we
                        assume responsibility for it until it reaches your
                        doorstep. Similar to nurturing an infant, we safeguard
                        your cargo with the utmost care, granting you peace of
                        mind throughout its journey.
                      </p>
                      <p>
                        Our dedication to delivering a comprehensive logistics
                        solution is evident through our dedicated single-window
                        customer service division. {companyname} offers
                        a comprehensive Door-To-Door service, catering to
                        various cargo requirements, including Full Container
                        Loads (FCL), Trailer Loads, and Less than Container
                        Loads (LCL). From the point of origin to the final
                        destination, we manage each step of the process with the
                        utmost care to ensure secure delivery.
                      </p>
                      <p>
                        Our Door-To-Door delivery services are designed to
                        simplify logistics management, reduce costs, and
                        minimize the risks associated with product loss or
                        damage. By entrusting us with your cargo, you can focus
                        on your core business while we handle the intricacies of
                        logistics.
                      </p>
                      <p>
                        We prioritize precision and customer-centric procedures,
                        which is why we create detailed action plans to
                        streamline the door delivery process. Our collaboration
                        with representatives and agents of the world's leading
                        ocean lines guarantees the effectiveness and reliability
                        of our door delivery execution.
                      </p>
                      <p>
                        With {companyname}, you'll experience seamless
                        communication from the point of origin to the
                        destination, covering all modes of goods movement for
                        the final delivery. Our stage-by-stage flow of
                        information provides real-time tracking and status
                        updates, ensuring transparency and keeping you
                        well-informed at every stage of the process.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
              {/*/w3l-blopagew3-right*/}
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5 order-lg-first order-last">
                <aside className="sidebar pe-lg-4">
                  <div className="sidebar-blog-category">
                    <div className="sidebar-widget sidebar-blog-category">
                      <div className="sidebar-title">
                        <h4>Our Freight Services</h4>
                      </div>
                      <ul className="blog-cat">
                        <li>
                          <Link to="/Air">
                            <i className="fa fa-angle-right me-2" />
                            Air Freight
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/Sea">
                            <i className="fa fa-angle-right me-2" />
                            Sea Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Surface">
                            <i className="fa fa-angle-right me-2" />
                            Surface Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Door">
                            <i className="fa fa-angle-right me-2" />
                            Door To Door Logistics
                          </Link>
                        </li>
                        <li>
                          <Link to="/Courier">
                            <i className="fa fa-angle-right me-2" />
                            Courier Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Warehousing">
                            <i className="fa fa-angle-right me-2" />
                            Warehousing & Packaging
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="sidebar-widget sidebar-blog-category">
                      <div className="side-post-banner mb-5">
                        <h3 className="title-left">Have Any Query</h3>
                        <Link
                          to="/Contact"
                          className="btn-style btn btn-primary mt-4"
                        >
                          {" "}
                          Contact Us{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              {/*//w3l-blopagew3-right*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
