import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light stroke py-lg-0">
            <h1>
              <Link className="navbar-brand pe-xl-5 pe-lg-4" to="/">
                <img src="assets/images/logo.png" alt="logo" style={{height:"100px"}}></img>
              </Link>
            </h1>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll text-uppercase">
                <li className="nav-item ">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    Company
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Whyus">
                    Why Us
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Freight Services <i className="fas fa-angle-down" />
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-2"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item pt-2" to="/Air">
                      Air Freight
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Sea">
                      Sea Freight
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Surface">
                      Surface Freight
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Door">
                      Door To Door Logistics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Courier">
                      Courier Freight
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Warehousing">
                      Warehousing & Packaging
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Trading Services <span className="fa fa-angle-down ms-1" />
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item pt-2" to="/Trading">
                      Automotive
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Trading">
                      Fruits & Vegetables
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Trading">
                      Mining & Minerals
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Trading">
                      Pharmaceuticals
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Trading">
                      Chemicals & Petrochemicals
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Trading">
                      Food & Beverages
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/Contact">
                    Contact
                  </Link>
                </li>
              </ul>
              {/*/search-right*/}
              <ul className="header-search d-flex mx-lg-4">
               
                <li className="nav-item propertyw3-btnhny">
                  <Link to="/Getquote" className="btn btn-style btn-primary">
                    Request A Quote
                  </Link>
                </li>
              </ul>
              {/*//search-right*/}
            </div>
            {/* toggle switch for light and dark theme */}
           
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
