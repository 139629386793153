import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">About Us</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> About Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page pt-4" id="about1">
        <div className="container ">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="title-content text-left">
            <h6 className="title-subhny">About Us</h6>
            <h3 className="title-w3l mb-2">
              Shipping Challenges, Delivering Solutions.
            </h3>
          </div>
          <div className="row mt-5 ab-inf-stat position-relative">
            <div
              className="col-lg-7 abw3-img mb-lg-0 mb-5 mt-4 "
              style={{ paddingTop: "40px" }}
            >
              <img
                src="assets/images/About 1.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="col-lg-5 ps-lg-5">
              <p>
                At {companyname}, our commitment to the shipping sector goes
                beyond the ordinary. With our headquarters strategically
                situated in Mumbai, Maharashtra, one of the world's leading
                maritime hubs, we have leveraged this advantageous location to
                establish ourselves as a prominent player in the international
                freight forwarding arena. Our journey has been marked by
                unwavering dedication to excellence, continuous innovation, and
                the relentless pursuit of solutions that empower our clients in
                the ever-evolving shipping landscape.
              </p>
              <p>
                What sets us apart is our holistic approach to servicing the
                shipping industry. We don't merely provide standard freight
                forwarding services; we offer a comprehensive suite of solutions
                that address the diverse needs of our clients. From meticulous
                planning and execution to leveraging cutting-edge technology for
                efficiency and transparency, Fordway Pte. Ltd. stands as a
                trusted partner for businesses across the globe.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="locations-1" id="locations">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subhny">Our Freight Services</h6>
              <h3 className="title-w3l mb-3">Freight Services</h3>
            </div>
            {/*/row-1*/}
            <div className="row pt-4">
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Air">
                      <img
                        className="img-fluid"
                        src="assets/images/Air Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Air">
                        <h3 className="title">Air Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Sea">
                      <img
                        className="img-fluid"
                        src="assets/images/Sea Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Sea">
                        <h3 className="title">Sea Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Surface">
                      <img
                        className="img-fluid"
                        src="assets/images/Surface Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Surface">
                        <h3 className="title">Surface Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Door">
                      <img
                        className="img-fluid"
                        src="assets/images/Door To Door Logistics.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Door">
                        <h3 className="title">Door To Door Logistics</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Courier">
                      <img
                        className="img-fluid"
                        src="assets/images/Courier Freight.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Courier">
                        <h3 className="title">Courier Freight</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="w3property-grid">
                  <div className="box16">
                    <Link to="/Warehousing">
                      <img
                        className="img-fluid"
                        src="assets/images/Warehousing & Packaging.jpg"
                        alt=""
                      />
                    </Link>
                    <div className="box-content">
                      <Link to="/Warehousing">
                        <h3 className="title">Warehousing & Packaging</h3>
                        <span className="post"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
