import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">Why Choose Us</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> Why Choose Us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-features-grids p-4">
        <div className="container">
        </div>
      </section>
      <section className="w3l-features py-5" id="features">
        <div className="container py-lg-5">
          <div className="row pb-lg-5 mb-lg-5 pt-lg-3">
            <div className="col-lg-5 text-left">
              <h6 className="title-subhny two">Why Choose Us</h6>
              <h3 className="title-w3l two">Solutions That Move You Forward</h3>
            </div>
            <div className="col-lg-7 text-left ps-lg-5">
              <p className="w3p-white">
                With a deep-rooted commitment to integrity, safety, and
                sustainability, we navigate the complex waters of the shipping
                industry with precision, ensuring that our clients' cargo
                reaches its destination securely and on time.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-features-grids">
        <div className="container">
          <div className="main-cont-wthree-2">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-bullseye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Mission</Link>
                  </h4>
                  <p className="text-para">
                    At our core, we are committed to providing our clients with
                    a comprehensive spectrum of cost-efficient forwarding
                    solutions. Our mission revolves around offering innovative,
                    reliable, and tailored logistics services that enable
                    businesses to thrive in an ever-evolving global marketplace.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-eye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Vision</Link>
                  </h4>
                  <p className="text-para">
                    We are driven by a steadfast dedication to delivering
                    top-tier customer service in the realm of freight
                    forwarding. Our vision encompasses a future where businesses
                    can entrust us with their logistics needs, knowing that we
                    will provide solutions that exceed their expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-team-main team py-5" id="team">
        <div className="container py-lg-5">
          <div className="text-center mb-2">
            <h6 className="title-subhny">Trading Services</h6>
            <h3 className="title-w3l">Trading Solution We Give</h3>
          </div>
          <div className="row team-row justify-content-center">
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Automotive.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Automotive</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            {/* end team member */}
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Fruits & Vegetables.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Fruits & Vegetables</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Mining & Minerals.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Mining & Minerals</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Pharmaceuticals.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Pharmaceuticals</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Chemicals & Petrochemicals.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Chemicals & Petrochemicals</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 team-wrap mt-lg-5 mt-4">
              <div className="member">
                <div className="member-img">
                  <img
                    src="assets/images/Food & Beverages.jpg"
                    alt=""
                    className="img-fluid radius-image"
                  />
                  <div className="social">
                    <Link to="/Trading" className="btn btn-style btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Food & Beverages</h4>
                  <span>Trading Services</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
