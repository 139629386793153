import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about">
          <div className="container py-5">
            <div className="w3breadcrumb-gids text-center py-sm-5 py-4">
              <div className="w3breadcrumb-info">
                <h2 className="w3ltop-title">Sea Freight</h2>
              </div>
              <ul className="breadcrumbs-custom-path">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">
                  <span className="fas fa-arrow-right mx-2" /> Sea Freight
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5 pt-0">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row bloghny-page justify-content-center">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <Link to="blog-single.html" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Sea Freight.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <h4>
                      <Link className="blog-desc">Sea Freight</Link>
                    </h4>
                    <p>
                      <p>
                        We possess the capability to organize both Full
                        Container Load (FCL) and Less than Container Load (LCL)
                        shipments for our global clientele. What sets us apart
                        is our robust partnerships with shipping liners,
                        allowing us to provide competitive rates and outstanding
                        service to our esteemed customers. We are dedicated to
                        ensuring the prompt, precise, and secure delivery of
                        merchandise to our clients, which contributes to the
                        continuous expansion of our sea freight services and the
                        growing satisfaction of our customers.
                      </p>
                      <p>
                        <strong>Details of Our Services:</strong>
                      </p>
                      <p>1. Break-Bulk / Out-of-gauge</p>
                      <p>2. Door-to-door service</p>
                      <p>3. FCL & LCL cargoes</p>
                      <p>4. Project cargo</p>
                      <p>5. Roll-on/Roll-off (Ro-Ro) cargoes</p>
                      <p>6. Cross-trade shipping</p>
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
              {/*/w3l-blopagew3-right*/}
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5 order-lg-first order-last">
                <aside className="sidebar pe-lg-4">
                  <div className="sidebar-blog-category">
                    <div className="sidebar-widget sidebar-blog-category">
                      <div className="sidebar-title">
                        <h4>Our Freight Services</h4>
                      </div>
                      <ul className="blog-cat">
                        <li>
                          <Link to="/Air">
                            <i className="fa fa-angle-right me-2" />
                            Air Freight
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/Sea">
                            <i className="fa fa-angle-right me-2" />
                            Sea Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Surface">
                            <i className="fa fa-angle-right me-2" />
                            Surface Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Door">
                            <i className="fa fa-angle-right me-2" />
                            Door To Door Logistics
                          </Link>
                        </li>
                        <li>
                          <Link to="/Courier">
                            <i className="fa fa-angle-right me-2" />
                            Courier Freight
                          </Link>
                        </li>
                        <li>
                          <Link to="/Warehousing">
                            <i className="fa fa-angle-right me-2" />
                            Warehousing & Packaging
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="sidebar-widget sidebar-blog-category">
                      <div className="side-post-banner mb-5">
                        <h3 className="title-left">Have Any Query</h3>
                        <Link
                          to="/Contact"
                          className="btn-style btn btn-primary mt-4"
                        >
                          {" "}
                          Contact Us{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              {/*//w3l-blopagew3-right*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
